import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import TypesetStyle from 'components/TypesetStyle';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "ibm-productive-type-set"
    }}>{`IBM Productive type set`}</h2>
    <p>{`Productive type is reserved for use in web-based product design, where the user needs to focus on tasks. The Productive styles are curated to create a series of clear user expectations about hierarchy.`}</p>
    <p>{`The Productive styles below introduce the new IBM Design Language tokens. The difference between the Productive and Expressive styles is mainly evident in the Headings. Aside from the token names, which are specifically calibrated for product vs. editorial designers (e.g. `}<inlineCode parentName="p">{`$label-01`}</inlineCode>{` vs. `}<inlineCode parentName="p">{`$caption-01`}</inlineCode>{`) — the Supporting and Body styles have the same values in both the Productive and Expressive sets.`}</p>
    <p>{`Even though IBM Plex contains a wide range of scales, it’s important to use curated sets below for their specified purpose. For consistent, reliable performance across various screen sizes, do not use fluid type in components or in product UI.`}</p>
    <TypesetStyle breakpointControls={false} typesets="supportingStyles,body,fixedHeadings" mdxType="TypesetStyle" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      